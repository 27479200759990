import React, { useEffect, useRef, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import * as styles from '../../scss/blog.module.scss';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sort from "../../components/elements/Sort"
import { ARTICLES_PER_PAGE, GRAPHQL_BASE, PRODUCTS_PER_PAGE } from "../../spectory-config"
import { getArticles } from "../../functions/queries/articles"
import Button from "../../components/elements/Button"
import BlogItem from "../../components/elements/BlogGrid/blogItem"
import CategorySelect from './categories.js';

const BlogArchivePage = ({ serverData }) => {
    console.log("ARTICLES LOADED INIT", serverData)

    const [articles, setArticles] = useState([]);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchs, setSearchs] = useState(null);
    const [loadMore, setLoadMore] = useState(true);
    const [page, setPage] = useState(1);
    const [categories, setCategories] = useState([]);


    useEffect(() => {
        let loadedArticles = [];

        serverData.posts.forEach((product, key) => {
            loadedArticles.push(product.node)
        });

        let searchs = {};

        window.location.search.replace('?', '').split('&').forEach(search => {
            let split = search.split('=');
            searchs[split[0]] = split[1];
        })

        if (searchs.s) {
            setSortedBy(searchs.s);
        } else {
            setArticles(loadedArticles);
        }

        // GET CATEGORIES
        setCategories(serverData.categories);

    }, []);

    useEffect(() => {
    }, [categories]);
    useEffect(() => {
        console.log("ARTICLES LOADED UPDATE", page, articles)
    }, [page, articles]);

    const loadMoreAction = (_page = null) => {

        if (_page == null) {
            _page = page;
        }

        let query = {};

        query.skip = ARTICLES_PER_PAGE * _page;
        query.limit = ARTICLES_PER_PAGE;

        query.categories = [];

        categories.map(category => {
            if (category.selected) {
                query.categories.push(category.slug);
            }
        });

        getArticles(query).then(newArticles => {
            let articleList = newArticles.articles;
            if (newArticles.data.skip > 0) {
                articleList = [...articles, ...newArticles.articles];
            }

            console.log("NEW ARTICLES", newArticles)
            console.log("NEW ARTICLES page", page);

            setArticles(articleList);
            setLoadMore(newArticles.count > articleList.length);

            setPage(_page + 1);

        });

    }

    const onSelect = (key) => {

        let _categories = [...categories];

        if (_categories[key].selected === undefined) {
            _categories[key].selected = false;
        }

        _categories[key].selected = !_categories[key].selected;

        setPage(0);
        setCategories(_categories);
        setArticles([]);
        loadMoreAction(0);
    }


    return (
        <Layout
        >
            <SEO title={"Aktuelles"} />
            <div className={`container`} style={{ minHeight: '90vh', paddingTop: 100 }}>
                <div className={`col12`}>
                    <div>
                        <h1>Aktuelles</h1>
                        <div className={styles.categorySelectWrapper}>
                            {
                                categories.map((category, key) => {
                                    return <div onClick={() => onSelect(key)} className={`${styles.categorySelectContainer} ${category.selected ? styles.selected : undefined}`}>
                                        {category.name}
                                    </div>
                                })
                            }
                        </div>

                        <div className={`${styles.container} ${styles.grid}`} style={{ marginTop: 100 }}>
                        {
                            articles.map((article, key) => {
                                return <BlogItem key={key} {...article} />
                            })
                        }
                        </div>

                        {
                            loadMore === true && <Button
                                title="mehr laden"
                                additionalStyle={{ marginTop: 30, marginBottom: 30 }}
                                type="primary"
                                action={() => loadMoreAction()}
                            />
                        }
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default BlogArchivePage


export async function getServerData(context) {

    var axios = require('axios');

    var data = JSON.stringify({
        query: `{ 
            posts(first: 6) {
                edges {
                  node {
                    title
                    uri
                    date
                    featuredImage {
                      node {
                        mediaItemUrl
                        srcSet
                      }
                    }
                    excerpt
                  }
                }
              }
              categories(first: 20) {
                nodes {
                  slug
                  name
                }
            }
        }`,
        variables: {}
    });

    var config = {
        method: 'post',
        url: GRAPHQL_BASE,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };

    let response = await axios(config);

    return {
        props: {
            posts: response.data.data.posts.edges,
            categories: response.data.data.categories.nodes,
        }
    }
}