import { API_BASE, GRAPHQL_BASE } from '../../spectory-config';

const axios = require('axios');

export const getArticles = async (args) => {
    const res = await axios.post(`${API_BASE}/articles`, {
        ...args
    })

    console.log("res", res)
    return res.data;
}